import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Loading from './Components/SelectValue/Loading';
const Dashboard = lazy(() => import('./Pages/Dashboard')); 
const Home = lazy(() => import('./Pages/Home')); 
const Login = lazy(() => import('./Pages/Login')); 
const ProtectedRoute = lazy(() => import('./Pages/Protected/ProtectedRoute')); 
const Signup = lazy(() => import('./Pages/SIgnup')); 
const Otp = lazy(() => import('./Pages/SIgnup/Otp')); 
const Registration = lazy(() => import('./Pages/SIgnup/Registration')); 
const SignUpNoBvn = lazy(() => import('./Pages/SignUpNoBvn')); 
const OtpNoBvn = lazy(() => import('./Pages/SignUpNoBvn/Otp')); 
const RegistrationNoBvn = lazy(() => import('./Pages/SignUpNoBvn/Registration')); 


function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading open={true} />}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/register' element={<Signup />} />
          <Route path='/otp' element={<Otp />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/registerNoBvn' element={<SignUpNoBvn />} />
          <Route path='/otpNoBvn' element={<OtpNoBvn />} />
          <Route path='/registrationNoBvn' element={<RegistrationNoBvn />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}






export default App;
