import image from "../images/zippylogo.png";

export const VERIFICATION_API_KEY = 6128892;
export const VERIFICATION_API_CLIENT_ID = "YY590KH-452-FGB";
export const API_CONTENT_TYPE = "application/x-www-form-urlencoded";
export const UPLOAD_CONTENT_TYPE = "multipart/form-data";
export const WTL_API_BASE_URL = "https://payvantage.com.ng/wtl/api/activity/";
export const API_BASE_URL =
  "https://zippyworld.net/zippy_world_live_api/v1/api"; // test
export const INVOICE_BASE_URL =
  "http://161.35.56.41/zippy_world_live_api/invoice/api";
export const ZIPPY_API_BASE_URL =
  "https://zippyworld.net/zippy_world_test_api/v1/api"; // live
export const VERIFICATION_API_URL =
  "https://zippyworld.net/zippy_world_live_api/v1/api";
export const HEADER = {
  "x-api-key": VERIFICATION_API_KEY,
  "content-type": API_CONTENT_TYPE,
};
export const UPLOAD_HEADER = {
  "x-api-key": VERIFICATION_API_KEY,
  "content-type": UPLOAD_CONTENT_TYPE,
};
export const NairaSymbol = () => {
  return <span className="text-sm font-semibold text-white-500">₦</span>;
};

export const DSTV_SERVICE_CODE = "dstv";
export const DSTV_OPERATOR_CODE = "ZWCO01";
export const GOTV_SERVICE_CODE = "gotv";
export const GOTV_OPERATOR_CODE = "ZWCO01";
export const STARTIMES_SERVICE_CODE = "startimes";
export const STARTIMES_OPERATOR_CODE = "ZWCO02";

export const AmountFormatter = ({ amount, currency }) => {
  const formattedAmount = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(amount);

  return formattedAmount;
};
export const FormattedDate = ({ dateFromTable }) => {
  const dateString = dateFromTable;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return <div>{formattedDate}</div>;
};

export const FormattedDateNoTime = ({ dateFromTable }) => {
  const dateString = dateFromTable;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });

  return <div>{formattedDate}</div>;
};

export const FormattedDates = ({ dateFromTable }) => {
  const dateString = dateFromTable;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return formattedDate;
};

export const retrieveFromLocalStorage = (keys) => {
  const data = {};
  keys.forEach((key) => {
    const persistedState = sessionStorage.getItem(key);
    data[key] = persistedState ? JSON.parse(persistedState) : null;
  });

  return data;
};

export const receipt = (props, accountNo, fullName) => {
  const htmlContent = `
<html>
  <head>
    <title>Transaction Receipt</title>
    <style>

    .monoFont{
      font-family: monospace;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin:20px;
     }
  
  .receiptHeading{
      color: #127EC8;
      background-color: #127EC830;
      padding: 18px 16px 18px 16px;
      font-weight: 500;
      border-radius: 6px 6px 0px 0px;
  }
  
  .transactionBody{
    padding: 20px;
    border: 1px solid #00000020;
    border-radius: 0px 0px 6px 6px;
  }
  
  .serviceInfo{
      display: flex;
      margin-bottom: 24px;
      gap: 16px;
      font-size: 20px;
      line-height: 28px;
  }
  
  .transaction_container{
font-size: 14px;
line-height: 20px;
margin-bottom:24px
  }
  
  .opacity{
  opacity: 0.5;
  }
  
  .bottom_line{
  padding-bottom: 8px;
  border-bottom:1px solid #00000010;
  }
  
  .padding_right{
      padding-right: 20px;
  }
  
  .truncate_text{
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
  }
  
  .two_column{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      gap: 32px;
  }
  
  .footer_container{
      border-top: 1px dashed #00000040;
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 16px;

  }
  
  .span-1{
      grid-column: span 1 / span 1;
  }
  
  .span-5{
      grid-column: span 5 / span 5;
  }

  .footer-text{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5
  }
  
  @media (min-width: 768px) {
    .monoFont{
      font-family: monospace;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
      .receiptHeading{
        padding: 24px 16px 24px 16px;
        font-size: 16px;
      }
  }
    </style>
  </head>
  <body class="monoFont">
  <div>
  <div class="receiptHeading">
    Transaction receipt.
  </div>
  <div class="transactionBody">
    <div class="serviceInfo">
      <div>${
        props["customer_wallet_result"].service_account_name.toUpperCase() ?? ""
      }</div>
      <div >-</div>
      <div>${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Zippyworld Customer Details:</div>
      <div class="bottom_line">
        <span>${fullName}</span> <br />
        <span>${accountNo} </span> <br />
        <span>Zippyworld</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Receiver:</div>
      <div class="bottom_line">
        <span>${
          props["customer_wallet_result"].service_account_name.toUpperCase() ??
          ""
        }</span> <br />
        <span>Meter No:${
          props["service_result"].card_identity ?? ""
        }</span> <br />
        <span>Token:${props["service_result"].token ?? ""}</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Amount:</div>
      <div class="bottom_line">${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Timestamp:</div>
      <div class="bottom_line">${FormattedDates({
        dateFromTable: props["service_result"].time_out,
      })}</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Description:</div>
      <div class="bottom_line">
        ${props["customer_wallet_result"].operation_summary ?? ""}
      </div>
    </div>
    <div class="two_column">
      <div>
        <div class="opacity">Reference number:</div>
        <div class="bottom_line truncate_text">
        ${props["service_result"].operation_id ?? ""}        </div>
      </div>
      <div>
        <div class="opacity">Status:</div>
        <div class="bottom_line">${props["message"] ?? ""}</div>
      </div>
    </div>

    <div class="footer_container">
      <div class="span-1">
      <img src="${image}" alt="" style="width:100%;"/>
      </div>
      <div class="span-5">
        <p class="footer-text">
          This is an electronic receipt of a transaction and does not
          require any signature. The authenticity of transaction can be
          confirmed with Zippyworld. <br /> For any other assitance, reach out to us 
          on +234 903 985 5986 or email zippyworld@macrotelgroup.co
        </p>
      </div>
    </div>
  </div>
  </body>
</html>
`;

  return htmlContent;
};

export const airtimeReceipt = (props, accountNo, fullName) => {
  const htmlContent = `
<html>
  <head>
    <title>Transaction Receipt</title>
    <style>

    .monoFont{
      font-family: monospace;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin:20px;
     }
  
  .receiptHeading{
      color: #127EC8;
      background-color: #127EC830;
      padding: 18px 16px 18px 16px;
      font-weight: 500;
      border-radius: 6px 6px 0px 0px;
  }
  
  .transactionBody{
    padding: 20px;
    border: 1px solid #00000020;
    border-radius: 0px 0px 6px 6px;
  }
  
  .serviceInfo{
      display: flex;
      margin-bottom: 24px;
      gap: 16px;
      font-size: 20px;
      line-height: 28px;
  }
  
  .transaction_container{
font-size: 14px;
line-height: 20px;
margin-bottom:24px
  }
  
  .opacity{
  opacity: 0.5;
  }
  
  .bottom_line{
  padding-bottom: 8px;
  border-bottom:1px solid #00000010;
  }
  
  .padding_right{
      padding-right: 20px;
  }
  
  .truncate_text{
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
  }
  
  .two_column{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      gap: 32px;
  }
  
  .footer_container{
      border-top: 1px dashed #00000040;
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 16px;

  }
  
  .span-1{
      grid-column: span 1 / span 1;
  }
  
  .span-5{
      grid-column: span 5 / span 5;
  }

  .footer-text{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5
  }
  
  @media (min-width: 768px) {
    .monoFont{
      font-family: monospace;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
      .receiptHeading{
        padding: 24px 16px 24px 16px;
        font-size: 16px;
      }
  }
    </style>
  </head>
  <body class="monoFont">
  <div>
  <div class="receiptHeading">
    Transaction receipt.
  </div>
  <div class="transactionBody">
    <div class="serviceInfo">
      <div>${
        props["customer_wallet_result"].service_account_name.toUpperCase() ?? ""
      }</div>
      <div >-</div>
      <div>${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Zippyworld Customer Details:</div>
      <div class="bottom_line">
        <span>${fullName}</span> <br />
        <span>${accountNo} </span> <br />
        <span>Zippyworld</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Receiver:</div>
      <div class="bottom_line">
        <span>${
          props["customer_wallet_result"].service_account_name.toUpperCase() ??
          ""
        }</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Amount:</div>
      <div class="bottom_line">${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Timestamp:</div>
      <div class="bottom_line">${FormattedDates({
        dateFromTable: props["service_result"].time_out,
      })}</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Description:</div>
      <div class="bottom_line">
        ${props["customer_wallet_result"].operation_summary ?? ""}
      </div>
    </div>
    <div class="two_column">
      <div>
        <div class="opacity">Reference number:</div>
        <div class="bottom_line truncate_text">
        ${props["service_result"].operation_id ?? ""}        </div>
      </div>
      <div>
        <div class="opacity">Status:</div>
        <div class="bottom_line">${props["message"] ?? ""}</div>
      </div>
    </div>

    <div class="footer_container">
      <div class="span-1">
      <img src="${image}" alt="" style="width:100%;"/>
      </div>
      <div class="span-5">
        <p class="footer-text">
          This is an electronic receipt of a transaction and does not
          require any signature. The authenticity of transaction can be
          confirmed with Zippyworld. <br /> For any other assitance, reach out to us 
          on +234 903 985 5986 or email zippyworld@macrotelgroup.co
        </p>
      </div>
    </div>
  </div>
  </body>
</html>
`;

  return htmlContent;
};

export const cableReceipt = (props, accountNo, fullName) => {
  const htmlContent = `
<html>
  <head>
    <title>Transaction Receipt</title>
    <style>

    .monoFont{
      font-family: monospace;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin:20px;
     }
  
  .receiptHeading{
      color: #127EC8;
      background-color: #127EC830;
      padding: 18px 16px 18px 16px;
      font-weight: 500;
      border-radius: 6px 6px 0px 0px;
  }
  
  .transactionBody{
    padding: 20px;
    border: 1px solid #00000020;
    border-radius: 0px 0px 6px 6px;
  }
  
  .serviceInfo{
      display: flex;
      margin-bottom: 24px;
      gap: 16px;
      font-size: 20px;
      line-height: 28px;
  }
  
  .transaction_container{
font-size: 14px;
line-height: 20px;
margin-bottom:24px
  }
  
  .opacity{
  opacity: 0.5;
  }
  
  .bottom_line{
  padding-bottom: 8px;
  border-bottom:1px solid #00000010;
  }
  
  .padding_right{
      padding-right: 20px;
  }
  
  .truncate_text{
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
  }
  
  .two_column{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      gap: 32px;
  }
  
  .footer_container{
      border-top: 1px dashed #00000040;
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 16px;

  }
  
  .span-1{
      grid-column: span 1 / span 1;
  }
  
  .span-5{
      grid-column: span 5 / span 5;
  }

  .footer-text{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5
  }
  
  @media (min-width: 768px) {
    .monoFont{
      font-family: monospace;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
      .receiptHeading{
        padding: 24px 16px 24px 16px;
        font-size: 16px;
      }
  }
    </style>
  </head>
  <body class="monoFont">
  <div>
  <div class="receiptHeading">
    Transaction receipt.
  </div>
  <div class="transactionBody">
    <div class="serviceInfo">
      <div>${
        props["customer_wallet_result"].service_account_name.toUpperCase() ?? ""
      }</div>
      <div >-</div>
      <div>${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Zippyworld Customer Details:</div>
      <div class="bottom_line">
        <span>${fullName}</span> <br />
        <span>${accountNo} </span> <br />
        <span>Zippyworld</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Receiver:</div>
      <div class="bottom_line">
        <span>${
          props["customer_wallet_result"].service_account_name.toUpperCase() ??
          ""
        }</span> <br />
        <span>Card Number:${props["service_result"].card_identity ?? ""}</span> 
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Amount:</div>
      <div class="bottom_line">${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Timestamp:</div>
      <div class="bottom_line">${FormattedDates({
        dateFromTable: props["service_result"].time_out,
      })}</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Description:</div>
      <div class="bottom_line">
        ${props["customer_wallet_result"].operation_summary ?? ""}
      </div>
    </div>
    <div class="two_column">
      <div>
        <div class="opacity">Reference number:</div>
        <div class="bottom_line truncate_text">
        ${props["service_result"].operation_id ?? ""}        </div>
      </div>
      <div>
        <div class="opacity">Status:</div>
        <div class="bottom_line">${props["message"] ?? ""}</div>
      </div>
    </div>

    <div class="footer_container">
      <div class="span-1">
      <img src="${image}" alt="" style="width:100%;"/>
      </div>
      <div class="span-5">
        <p class="footer-text">
          This is an electronic receipt of a transaction and does not
          require any signature. The authenticity of transaction can be
          confirmed with Zippyworld. <br /> For any other assitance, reach out to us 
          on +234 903 985 5986 or email zippyworld@macrotelgroup.co
        </p>
      </div>
    </div>
  </div>
  </body>
</html>
`;

  return htmlContent;
};

export const TransferReceipt = (props, accountNo, fullName) => {
  const htmlContent = `
<html>
  <head>
    <title>Transaction Receipt</title>
    <style>

    .monoFont{
      font-family: monospace;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin:20px;
     }
  
  .receiptHeading{
      color: #127EC8;
      background-color: #127EC830;
      padding: 18px 16px 18px 16px;
      font-weight: 500;
      border-radius: 6px 6px 0px 0px;
  }
  
  .transactionBody{
    padding: 20px;
    border: 1px solid #00000020;
    border-radius: 0px 0px 6px 6px;
  }
  
  .serviceInfo{
      display: flex;
      margin-bottom: 24px;
      gap: 16px;
      font-size: 20px;
      line-height: 28px;
  }
  
  .transaction_container{
font-size: 14px;
line-height: 20px;
margin-bottom:24px
  }
  
  .opacity{
  opacity: 0.5;
  }
  
  .bottom_line{
  padding-bottom: 8px;
  border-bottom:1px solid #00000010;
  }
  
  .padding_right{
      padding-right: 20px;
  }
  
  .truncate_text{
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
  }
  
  .two_column{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      gap: 32px;
  }
  
  .footer_container{
      border-top: 1px dashed #00000040;
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 16px;

  }
  
  .span-1{
      grid-column: span 1 / span 1;
  }
  
  .span-5{
      grid-column: span 5 / span 5;
  }

  .footer-text{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5
  }
  
  @media (min-width: 768px) {
    .monoFont{
      font-family: monospace;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
      .receiptHeading{
        padding: 24px 16px 24px 16px;
        font-size: 16px;
      }
  }
    </style>
  </head>
  <body class="monoFont">
  <div>
  <div class="receiptHeading">
    Transaction receipt.
  </div>
  <div class="transactionBody">
    <div class="serviceInfo">
      <div>${
        props["customer_wallet_result"].service_account_name.toUpperCase() ?? ""
      }</div>
      <div >-</div>
      <div>${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Payer:</div>
      <div class="bottom_line">
        <span>${fullName}</span> <br />
        <span>${accountNo} </span> <br />
        <span>Zippyworld</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Receiver:</div>
      <div class="bottom_line">
        <span>${
          props["service_result"].account_name.toUpperCase() ?? ""
        }</span> <br />
        <span>Account Number:${
          props["service_result"].account_no ?? ""
        }</span> <br />
        <span>Bank Name:${props["service_result"].bank_name ?? ""}</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Amount:</div>
      <div class="bottom_line">${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Timestamp:</div>
      <div class="bottom_line">${FormattedDates({
        dateFromTable: props["service_result"].time_out,
      })}</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Description:</div>
      <div class="bottom_line">
        ${props["customer_wallet_result"].operation_summary ?? ""}
      </div>
    </div>
    <div class="two_column">
      <div>
        <div class="opacity">Reference number:</div>
        <div class="bottom_line truncate_text">
        ${props["service_result"].operation_id ?? ""}        </div>
      </div>
      <div>
        <div class="opacity">Status:</div>
        <div class="bottom_line">${props["message"] ?? ""}</div>
      </div>
    </div>

    <div class="footer_container">
      <div class="span-1">
      <img src="${image}" alt="" style="width:100%;"/>
      </div>
      <div class="span-5">
        <p class="footer-text">
          This is an electronic receipt of a transaction and does not
          require any signature. The authenticity of transaction can be
          confirmed with Zippyworld. <br /> For any other assitance, reach out to us 
          on +234 903 985 5986 or email zippyworld@macrotelgroup.co
        </p>
      </div>
    </div>
  </div>
  </body>
</html>
`;

  return htmlContent;
};

export const ProvidusReceipt = (props, accountNo, fullName) => {
  const htmlContent = `
<html>
  <head>
    <title>Transaction Receipt</title>
    <style>

    .monoFont{
      font-family: monospace;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin:20px;
     }
  
  .receiptHeading{
      color: #127EC8;
      background-color: #127EC830;
      padding: 18px 16px 18px 16px;
      font-weight: 500;
      border-radius: 6px 6px 0px 0px;
  }
  
  .transactionBody{
    padding: 20px;
    border: 1px solid #00000020;
    border-radius: 0px 0px 6px 6px;
  }
  
  .serviceInfo{
      display: flex;
      margin-bottom: 24px;
      gap: 16px;
      font-size: 20px;
      line-height: 28px;
  }
  
  .transaction_container{
font-size: 14px;
line-height: 20px;
margin-bottom:24px
  }
  
  .opacity{
  opacity: 0.5;
  }
  
  .bottom_line{
  padding-bottom: 8px;
  border-bottom:1px solid #00000010;
  }
  
  .padding_right{
      padding-right: 20px;
  }
  
  .truncate_text{
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
  }
  
  .two_column{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      gap: 32px;
  }
  
  .footer_container{
      border-top: 1px dashed #00000040;
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 16px;

  }
  
  .span-1{
      grid-column: span 1 / span 1;
  }
  
  .span-5{
      grid-column: span 5 / span 5;
  }

  .footer-text{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5
  }
  
  @media (min-width: 768px) {
    .monoFont{
      font-family: monospace;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
      .receiptHeading{
        padding: 24px 16px 24px 16px;
        font-size: 16px;
      }
  }
    </style>
  </head>
  <body class="monoFont">
  <div>
  <div class="receiptHeading">
    Transaction receipt.
  </div>
  <div class="transactionBody">
    <div class="serviceInfo">
      <div>${
        props["customer_wallet_result"].service_account_name.toUpperCase() ?? ""
      }</div>
      <div >-</div>
      <div>${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Receiver:</div>
      <div class="bottom_line">
        <span>${fullName}</span> <br />
        <span>${accountNo} </span> <br />
        <span>Zippyworld</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Payer:</div>
      <div class="bottom_line">
        <span>${
          props["service_result"].sourceAccountName.toUpperCase() ?? ""
        }</span> <br />
        <span>Bank Name:${props["service_result"].sourceBankName ?? ""}</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Amount:</div>
      <div class="bottom_line">${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Timestamp:</div>
      <div class="bottom_line">${FormattedDates({
        dateFromTable: props["customer_wallet_result"].operation_at,
      })}</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Description:</div>
      <div class="bottom_line">
        ${props["customer_wallet_result"].operation_summary ?? ""}
      </div>
    </div>
    <div class="two_column">
      <div>
        <div class="opacity">Reference number:</div>
        <div class="bottom_line truncate_text">
        ${props["customer_wallet_result"].reference_id ?? ""}        </div>
      </div>
      <div>
        <div class="opacity">Status:</div>
        <div class="bottom_line">${props["message"] ?? ""}</div>
      </div>
    </div>

    <div class="footer_container">
      <div class="span-1">
      <img src="${image}" alt="" style="width:100%;"/>
      </div>
      <div class="span-5">
        <p class="footer-text">
          This is an electronic receipt of a transaction and does not
          require any signature. The authenticity of transaction can be
          confirmed with Zippyworld. <br /> For any other assitance, reach out to us 
          on +234 903 985 5986 or email zippyworld@macrotelgroup.co
        </p>
      </div>
    </div>
  </div>
  </body>
</html>
`;

  return htmlContent;
};

export const walletReceipt = (props, accountNo, fullName) => {
  const htmlContent = `
<html>
  <head>
    <title>Transaction Receipt</title>
    <style>

    .monoFont{
      font-family: monospace;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin:20px;
     }
  
  .receiptHeading{
      color: #127EC8;
      background-color: #127EC830;
      padding: 18px 16px 18px 16px;
      font-weight: 500;
      border-radius: 6px 6px 0px 0px;
  }
  
  .transactionBody{
    padding: 20px;
    border: 1px solid #00000020;
    border-radius: 0px 0px 6px 6px;
  }
  
  .serviceInfo{
      display: flex;
      margin-bottom: 24px;
      gap: 16px;
      font-size: 20px;
      line-height: 28px;
  }
  
  .transaction_container{
font-size: 14px;
line-height: 20px;
margin-bottom:24px
  }
  
  .opacity{
  opacity: 0.5;
  }
  
  .bottom_line{
  padding-bottom: 8px;
  border-bottom:1px solid #00000010;
  }
  
  .padding_right{
      padding-right: 20px;
  }
  
  .truncate_text{
   overflow: hidden;
   text-overflow:ellipsis;
   white-space: nowrap;
  }
  
  .two_column{
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 20px;
      gap: 32px;
  }
  
  .footer_container{
      border-top: 1px dashed #00000040;
      padding-top: 32px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 16px;

  }
  
  .span-1{
      grid-column: span 1 / span 1;
  }
  
  .span-5{
      grid-column: span 5 / span 5;
  }

  .footer-text{
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5
  }
  
  @media (min-width: 768px) {
    .monoFont{
      font-family: monospace;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
  }
      .receiptHeading{
        padding: 24px 16px 24px 16px;
        font-size: 16px;
      }
  }
    </style>
  </head>
  <body class="monoFont">
  <div>
  <div class="receiptHeading">
    Transaction receipt.
  </div>
  <div class="transactionBody">
    <div class="serviceInfo">
      <div>${
        props["customer_wallet_result"].service_account_name.toUpperCase() ?? ""
      }</div>
      <div >-</div>
      <div>${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Payer:</div>
      <div class="bottom_line">
        <span>${fullName}</span> <br />
        <span>${accountNo} </span> <br />
        <span>Zippyworld</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Receiver:</div>
      <div class="bottom_line">
        <span>${
          props["service_result"].recipient_name.toUpperCase() ?? ""
        }</span> <br />
        <span>Account Number:${props["service_result"].customer_id ?? ""}</span>
      </div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Amount:</div>
      <div class="bottom_line">${
        AmountFormatter({ amount: props["customer_wallet_result"].amount }) ??
        ""
      }</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Timestamp:</div>
      <div class="bottom_line">${FormattedDates({
        dateFromTable: props["service_result"].time_out,
      })}</div>
    </div>
    <div class="transaction_container">
      <div class="opacity">Description:</div>
      <div class="bottom_line">
        ${props["customer_wallet_result"].operation_summary ?? ""}
      </div>
    </div>
    <div class="two_column">
      <div>
        <div class="opacity">Reference number:</div>
        <div class="bottom_line truncate_text">
        ${props["service_result"].operation_id ?? ""}        </div>
      </div>
      <div>
        <div class="opacity">Status:</div>
        <div class="bottom_line">${props["message"] ?? ""}</div>
      </div>
    </div>

    <div class="footer_container">
      <div class="span-1">
      <img src="${image}" alt="" style="width:100%;"/>
      </div>
      <div class="span-5">
        <p class="footer-text">
          This is an electronic receipt of a transaction and does not
          require any signature. The authenticity of transaction can be
          confirmed with Zippyworld. <br /> For any other assitance, reach out to us 
          on +234 903 985 5986 or email zippyworld@macrotelgroup.co
        </p>
      </div>
    </div>
  </div>
  </body>
</html>
`;

  return htmlContent;
};

export const waybill = (itemList, itemSummary, myProfile, data) => {
  const htmlContent = ` 
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      .monoFont {
        font-family: monospace;
      }
      body {
        font-family: 'monospace';
  
      }
      .font_24 {
        font-size: 24px;
      }
  
      .bottom_line {
        padding-bottom: 12px;
        border-bottom: 1px solid #00000010;
        margin-bottom: 24px;
      }
  
      .bottom_line_dashed {
        padding-bottom: 12px;
        border-bottom: 1px dashed #00000030;
        margin-bottom: 12px;
      }
  
      .four_col {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
  
      .two_col {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }
  
      .two_col_right {
        display: grid;
        grid-template-columns: 2fr 2fr;
      }
  
      .one_col_right {
        display: grid;
        grid-template-columns: 3fr 1fr;
      }
  
      .footer_col {
        display: grid;
        grid-template-columns: 1fr 5fr;
      }

      .col-two {
        display: grid;
        grid-template-columns: 2fr 2fr;
      }
  
      .column {
        border: 1px solid #00000030;
        padding: 10px 8px;
      }
  
      .one_col {
        border: 1px solid #00000030;
        padding: 20px;
        text-align: center;
        background-color: #127ec820;
        color: #127ec8;
        font-weight: bold;
      }
  
      .total {
        background-color: #127ec820;
        color: #127ec8;
      }
  
      .bold {
        font-weight: 800;
      }
  
      .medium {
        font-weight: 700;
        padding-right: 8px;
      }
  
      .p-bottom {
        padding-bottom: 8px;
      }
    </style>
  </head>
  <body>
    <div>
      <p class="bottom_line_dashed font_24">Waybill</p>
      <div>
        <div class="four_col">
          <div class="column bold">Order ID:</div>
          <div class="column">${itemList["0"]["invoice_id"]}</div>
          <div class="column bold">Order Date:</div>
          <div class="column">${itemSummary["0"]["inserted_dt"]}</div>
        </div>
        <div class="four_col">
          <div class="column bold">Seller</div>
          <div class="column">${myProfile["0"]["company"]}</div>
          <div class="column bold">Receiver</div>
          <div class="column">${itemSummary["0"]["names"]}</div>
        </div>
        <div class="two_col">
          <div class="column bold">From:</div>
          <div class="column">
          <div class="p-bottom"><span class="bold">Phone number: </span>${itemSummary["0"]["phonenumber"]}</div>
          <div class="p-bottom"><span class="bold">Email address: </span>${itemSummary["0"]["email"]}</div>
          <div><span class="bold">Address: </span>${itemSummary["0"]["address"]}</div>          
           </div>
        </div>
        <div class="two_col">
          <div class="column bold">To:</div>
          <div class="column">
            <div class="p-bottom"><span class="bold">Phone number: </span>${myProfile["0"]["phonenumber"]}</div>
            <div class="p-bottom"><span class="bold">Email address: </span>${myProfile["0"]["email"]}</div>
            <div><span class="bold">Address: </span>${myProfile["0"]["address"]}</div>

            </div>
        </div>
        <div class="one_col">
          Goods
        </div>
        <div class="four_col">
          <div class="column bold">SN</div>
          <div class="column bold">Unit</div>
          <div class="column bold">Description</div>
          <div class="column bold">Price</div>
        </div>
        <!-- map transactions here -->
        <div>
        ${itemList}
        </div>
        <!-- end map transactions here -->
      </div>
    </div>
    <div className="my-32" style="
      margin-top: 50px;
      margin-bottom: 50px ;
  "></div>
    <div style="align-items: center;" class="footer_col">
    <div>
    <img src="${image}" alt="" style="text-align: center; width:100px;"/>
    </div>
    <div>

<p><b>Disclaimer:</b> This electronic waybill is generated from Zippyworld platform. It
                        does not require signature and the authenticity is subject to a liable transaction between the
                        app user and the buyer. It is the responsibility of the platform users and buyers to deal in
                        truth and trust in line with all applicable laws wherever and whenever this is being used for
                        ease of transaction confirmation and cash payment for goods and services that are delivered to
                        the buyer.
                    </p>
                    <p>You can reach out to Zippyworld to confirm the authenticity of this receipt and not transactions
                        between the parties. On +234 903 985 5986 or email hello@zippyworld.net or
                        zippyworld@macrotelgroup.com </p>
                        </div>
                        
  </div>
  </body>
  </html>  
 `;

  return htmlContent;
};

export const invoice = (itemList, itemSummary, myProfile, data) => {
  const htmlContent = ` 
  <!DOCTYPE html>
<html>
<head>
<link href='https://fonts.googleapis.com/css?family=Nunito' rel='stylesheet'>

  <style>
   body {
      font-family: 'Nunito';

    }

    .font_24 {
      font-size: 20px;
    }

    .bottom_line {
      padding-bottom: 32px;
      border-bottom: 1px solid #00000010;
      margin-bottom: 22px;
    }

    .bottom_line_dashed {
      padding-bottom: 22px;
      border-bottom: 1px dashed #00000030;
      margin-bottom: 40px;
    }

    .four_col {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    p {
      margin: 0;
      padding: 0;
  }

    .two_col {
      display: grid;
      grid-template-columns: 1fr 3fr;
    }

    .two_col_right {
      display: grid;
      grid-template-columns: 2fr 2fr;
    }

    .one_col_right {
      display: grid;
      grid-template-columns: 3fr 1fr;
    }

    .footer_col {
      display: grid;
      grid-template-columns: 1fr 3fr;
    }

    .col-two {
      display: grid;
      grid-template-columns: 2fr 2fr;
    }

    .column {
      border: 1px solid #00000030;
      padding: 10px 8px;
    }

    .one_col {
      border: 1px solid #00000030;
      padding: 20px;
      text-align: center;
      background-color: #127ec820;
      color: #127ec8;
      font-weight: bold;
    }

    .total {
      background-color: #127ec820;
      color: #127ec8;
    }

    .bold {
      font-weight: 800;
      margin-bottom: 20px;
    }

    .bolds {
      font-weight: 800;
    }

    .medium {
      font-weight: 700;
      padding-right: 8px;
    }

  </style>
</head>
<body>
  <div>
    <div class="col-two bottom_line_dashed">
      <div>
        <p class="font_24 bold">${myProfile["0"]["company"]}</p>
        <p>${myProfile["0"]["address"]}</p>
        <p>${myProfile["0"]["phonenumber"]}</p>
        <p>${myProfile["0"]["email"]}</p>
      </div>
      <div style="display:flex; justify-content:right">
      <img src=${myProfile["0"]["logo"]} alt="" style=" width:100px;"/>
      </div>
    </div>

    <div class="col-two bottom_line">
      <div>
        <div class="bold ">Payment information:</div>
        <p><span class="medium">Account name :</span>Zippyworld(${
          data.firstname + data.lastname
        })</p>
        <p><span class="medium">Account number :</span> ${data.account_no}</p>
        <p><span class="medium">Bank :</span>Providus bank</p>
        <p><span class="medium">Zippyworld wallet number :</span>${
          data.phonenumber
        }</p>
        <div class="bold">Invoice ID :${itemList["0"]["invoice_id"]}</div>
      </div>
      <div style="text-align:right">
        <div class="bold">Invoice to:</div>
        <p class="medium">${itemSummary["0"]["names"]}</p>
        <p>${itemSummary["0"]["address"]}</p>
        <p>${itemSummary["0"]["phonenumber"]}</p>
        <p>${itemSummary["0"]["email"]}</p>
        <div class="bold">Invoice Date :${itemSummary["0"]["inserted_dt"]}</div>
      </div>
    </div>

    <div class="four_col bolds">
      <div class="column">Description</div>
      <div class="column">Quantity</div>
      <div class="column">Unit cost</div>
      <div class="column">Total</div>
    </div>
    <!-- map transactions here -->
    <div>
    ${itemList}
    </div>
    <!-- end map transactions here -->

    <div class="two_col_right">
      <div></div>
      <div class="col-two">
        <div class="column medium">Sub-total</div>
        <div class="column">${AmountFormatter({
          amount: itemSummary["0"]["total"],
        })}</div>
      </div>
    </div>

    <div class="two_col_right">
      <div>.</div>
      <div class="col-two">
        <div class="column medium">VAT (0%)</div>
        <div class="column">${AmountFormatter({
          amount: itemSummary["0"]["vat"],
        })}</div>
      </div>
    </div>

    <div class="one_col_right bottom_line">
      <div>.</div>
      <div class="column medium total">${AmountFormatter({
        amount: itemSummary["0"]["amount_charge"],
      })}</div>
    </div>

    <div style="align-items: center;" class="footer_col">
      <div>
      <img src="${image}" alt="" style="width:20%;"/>
      </div>
      <div>This Invoice is Powered by Zippyworld. <br />
        Download Zippyworld on Google Play Store or App Store</div>
    </div>
  </div>
</body>
</html>

 `;

  return htmlContent;
};
