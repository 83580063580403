import {
  apiClient,
  apiLiveClient,
  invoiceApiClient,
  uploadInvoiceApiClient,
} from "../clients/api_client";
import { showErrorToast } from "../../../utils/api-utils";

export class APIService {
  static extractServerError(error) {
    let extracted;
    if (error.isAxiosError) {
      if (error.request) {
        extracted = ["Network error occurred"];
      } else {
        extracted = ["An unexpected error occurred"];
      }
    } else {
      extracted = [error.message || "An unexpected error occurred"];
    }
    extracted.forEach((e) => showErrorToast(e));
  }

  //LOGIN API CALL
  static async login(userCredentials) {
    try {
      return apiClient.post("/new_login", userCredentials);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // SEND OTP
  static async sendOTP(sendOTPCredentials) {
    try {
      return apiClient.post("/registration_token", sendOTPCredentials);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // VERIFY OTP

  static async verifyOTP(verifyOTPCredentials) {
    try {
      return apiClient.post("/token_manager", verifyOTPCredentials);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // VERIFY BVN

  static async verifyBVN(sendBVNCredentials) {
    try {
      return await apiClient.post("/bvn", sendBVNCredentials);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //GET SECURITY QUESTIONS
  static async fetchSecurityQuestions() {
    try {
      return await apiClient.get("/get_security_questions");
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //SIGN UP

  static async signUp(signUpCredentials) {
    try {
      return await apiClient.get("/user_creation", signUpCredentials);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //
  static async fetchUserBalance(phoneNumber) {
    try {
      return await apiClient.post(
        "/query_customer_wallet_balance",
        phoneNumber
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //fetchUserCommission
  static async fetchUserCommission(phoneNumber) {
    try {
      return await apiClient.post("/commission_earned", phoneNumber);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //fetchAccumulatedCommission

  static async fetchAccumulatedUserCommission(phoneNumber) {
    try {
      return await apiClient.post(
        "/query_customer_commission_wallet_balance",
        phoneNumber
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //fetchQrBalance

  static async fetchQrBalance(phoneNumber) {
    try {
      return await apiClient.post(
        "/query_qr_customer_wallet_balance",
        phoneNumber
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //fetchTransactionFlow
  static async fetchTransactionFlow(phoneNumber) {
    try {
      return await apiClient.post("/transaction_flow", phoneNumber);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //customer_txn_list
  static async fetchTransactionList(phoneNumber) {
    try {
      return await apiClient.post("/customer_txn_list", phoneNumber);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //customer_full_txn_list
  static async fetchFullTransactionList(phoneNumber) {
    try {
      return await apiClient.post("/customer_txn_full_details", phoneNumber);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }
  //get_network_operator
  static async fetchNetworkOperator() {
    try {
      return await apiClient.get("/network_operators");
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }
  //get airtime
  static async airtime_purchase(airtimeParameters) {
    try {
      return await apiClient.post("/airtime_purchase", airtimeParameters);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }
  //get network details
  static async fetchDataPlans(data) {
    try {
      return await apiClient.get(
        `/get_data_plans?service_code=${data["service_code"]}&network_code=${data["networkCodes"]}`
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }
  //get data
  static async data_purchase(dataParameters) {
    try {
      return await apiClient.post("/data_purchase", dataParameters);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get user_details
  static async user_details(userParameters) {
    try {
      return await apiClient.post("/user_detail", userParameters);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //wallet transfer
  static async wallet_transfer(walletParameters) {
    try {
      return await apiClient.post("/fund_transfer", walletParameters);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //bank transfer
  static async bank_transfer(walletParameters) {
    try {
      return await apiClient.post("/bank_transfer", walletParameters);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get banks
  static async get_banks() {
    try {
      return await apiClient.get("/banks");
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //check account number
  static async account_number(accountParameters) {
    try {
      return await apiClient.post("/bank_account_details", accountParameters);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get electric operators
  static async electric_operators(serviceCode) {
    try {
      return await apiClient.get(`/electric_operators?provider=${serviceCode}`);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //validate Electricity
  static async validate_electricity(validationParams) {
    try {
      return await apiLiveClient.post(
        "/electricity_validation",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //BUY ELECTRICITY
  static async subscribe_electricity(validationParams) {
    try {
      return await apiLiveClient.post("/electricity_vending", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //LIST OF PRIVATE ESTATES

  static async fetchPrivateEstateList() {
    try {
      return await apiClient.get("/private_estates");
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //generate token
  static async generateToken(validationParams) {
    try {
      return await apiLiveClient.post("/generate_token", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }
  //reset security details
  static async resetSecurityDetails(validationParams) {
    try {
      return await apiLiveClient.post(
        "/update_security_details",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // PRIVATE ESTATE ELECTRICITY

  static async buyEstateElectricity(validationParams) {
    try {
      return await apiClient.post(
        "/auto_private_power_vending",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // PRIVATE ESTATE METER NUMBER VALIDATION
  static async estateMeterNumberValidation(meterNumber) {
    try {
      return await apiClient.get(
        `/getgardenscustomerdetails?meternumber=${meterNumber["meter_number"]}`
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //GENERATE PASS TOKEN

  static async generatePassToken(validationParams) {
    try {
      return await apiClient.post("/private_tokens", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //VALIDATE GENERATED TOKEN

  static async validatePassToken(validationParams) {
    try {
      return await apiClient.post("/estate_token_validation", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //ESTATE HISTORY
  static async estateHistory(validationParams) {
    try {
      return await apiClient.post("/estate_token_history", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //ESTATE SERVICE PRODUCTS
  static async estateFibreProduct(validationParams) {
    try {
      return await apiClient.post("/estate_service_products", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //ESTATE PRODUCT PAYMENT
  static async estateProductPayment(validationParams) {
    try {
      return await apiClient.post("/estate_product_payment", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //FUND WALLET
  static async fundWallet(validationParams) {
    try {
      return await apiClient.post(
        "/monnify_payment_initialize",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //TOP UP WALLET
  static async topUpWallet(validationParams) {
    try {
      return await apiClient.post("/alternative_payment", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //GET SHAGO CABLE TV ADDONS
  static async fetchShagoCableTVAddons(productCode) {
    try {
      return await apiClient.get(
        `/get_shago_cabletv_product_addons?product_code=${productCode["product_code"]}`
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //GET SHAGO CARD VALIDATION
  static async shagoCardValidation(validationParams) {
    try {
      return await apiLiveClient.post(
        "/shago_cabletv_card_validation",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //GET  CARD VALIDATION
  static async cardValidation(validationParams) {
    try {
      return await apiLiveClient.post(
        "/cabletv_card_validation",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //subscribe shago cable tv
  static async subscribeShagoCableTv(validationParams) {
    try {
      return await apiClient.post(
        "/shago_cabletv_subscription",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //subscribe cable tv
  static async subscribeCableTv(validationParams) {
    try {
      return await apiClient.post("/cabletv_subscription", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get cable tv operators
  static async getCableOperators() {
    try {
      return await apiClient.get("get_cabletv_operators");
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get cable tv products

  static async getCableProducts(productCode) {
    try {
      return await apiClient.get(
        `get_cabletv_products?cable_service_code=${productCode}`
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get cable tv addons
  static async getCableAddons(data) {
    try {
      return await apiClient.get(
        `/get_cabletv_product_addons?cable_service_code=${data["initialValues"].cable_service_code}&product_code=${data["initialValues"].product_code}`
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //subscribe top up shago
  static async subscribeTopUpShagoCableTv(validationParams) {
    try {
      return await apiClient.post(
        "/topup_cabletv_subscription",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //wallet beneficiaries
  static async walletBeneficiaries(validationParams) {
    try {
      return await apiClient.post("/wallet_beneficiaries", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //save wallet beneficiaries
  static async saveWalletBeneficiaries(validationParams) {
    try {
      return await apiClient.post(
        "/save_wallet_beneficiaries",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //get bank beneficiaries
  static async bankBeneficiaries(phonenumber) {
    try {
      return await apiClient.get(
        `/bank_beneficiaries?phonenumber=${phonenumber["phonenumber"]}`
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //save bank beneficiaries
  static async saveBankBeneficiaries(validationParams) {
    try {
      return await apiClient.post("/save_bank_beneficiaries", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //save electricity beneficiaries
  static async saveElectricityBeneficiaries(validationParams) {
    try {
      return await apiClient.post(
        "/save_electricity_beneficiaries",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // electricity beneficiaries

  static async electricityBeneficiaries(validationParams) {
    try {
      return await apiClient.post(
        "/electricity_beneficiaries",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //cable beneficiaries

  static async cableBeneficiaries(validationParams) {
    try {
      return await apiClient.post("/cabletv_beneficiaries", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //save cable beneficiaries

  static async saveCableBeneficiaries(validationParams) {
    try {
      return await apiClient.post(
        "/save_cabletv_beneficiaries",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //create profile

  static async invoiceProfiling(validationParams) {
    try {
      return await invoiceApiClient.post(
        "/invoice_profiling",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //add product
  static async addProduct(validationParams) {
    try {
      return await invoiceApiClient.post("/save_product", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //profile details

  static async myProfile(validationParams) {
    try {
      return await invoiceApiClient.post("/my_profile", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // upload logo
  static async uploadLogo(validationParams) {
    try {
      return await uploadInvoiceApiClient.post(
        "/identity_upload",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //add new customer

  static async addNewCustomer(validationParams) {
    try {
      return await invoiceApiClient.post(
        "/save_invoice_customer",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  // customer List

  static async customerList(validationParams) {
    try {
      return await invoiceApiClient.post("/my_customer", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //add items
  static async addItems(validationParams) {
    try {
      return await invoiceApiClient.post(
        "/save_invoice_item",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //INVOICE HISTORY
  static async invoiceHistory(validationParams) {
    try {
      return await invoiceApiClient.post(
        "/customer_invoice_list",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //invoice list
  static async invoiceList(validationParams) {
    try {
      return await invoiceApiClient.post("/invoice_list", validationParams);
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }

  //referral
  static async referral(validationParams) {
    try {
      return await apiClient.post(
        "/customer_transaction_stand",
        validationParams
      );
    } catch (error) {
      APIService.extractServerError(error);
      throw error;
    }
  }
}
