import Axios from "axios";
import {VERIFICATION_API_URL, API_BASE_URL, HEADER, INVOICE_BASE_URL, UPLOAD_HEADER } from "../../../utils/constant";


export const apiClient = Axios.create({
    baseURL: API_BASE_URL,
    headers: HEADER,
    
});

export const invoiceApiClient = Axios.create({
    baseURL: INVOICE_BASE_URL,
    headers: HEADER,
});

export const apiLiveClient = Axios.create({
    baseURL: VERIFICATION_API_URL,
    headers: HEADER,
});

export const uploadInvoiceApiClient = Axios.create({
    baseURL: INVOICE_BASE_URL,
    headers: UPLOAD_HEADER,
});
